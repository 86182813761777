import { Component, ComponentApi, GraphicsProperties, View } from 'outpost';

export class Button implements Component {
    label: string;
    onClick: (api: ComponentApi) => void | Promise<void>;
    props: Partial<GraphicsProperties>

    constructor(label: string, onClick: ((api: ComponentApi) => void | Promise<void>) = () => { }, prop: Partial<GraphicsProperties> = {}) {
        this.label = label;
        this.onClick = onClick;
        this.props = prop
    }

    async runInteraction(api: ComponentApi): Promise<void> {
        await api.waitForUserInput({
            selectableComponents: [this],
            highlightHovered: {
                cursor: 'pointer',
                borderScale: 2
            }
        })

        await this.onClick(api);
    }

    render(view: View): void {
        view.paint()
            .backgroundColor('white')
            .borderColor('black')
            .borderWidth('3%')
            .borderRadius('10%')
            .text(this.label)
            .textSize('50%')
            .setProperties(this.props)
    }
}
globalThis.ALL_FUNCTIONS.push(Button);