import { Rect } from 'outpost';

export function rectsIntersect(rect: Rect, other: Rect): boolean {
    return rect.contains(other.x1, other.y1)
        || rect.contains(other.x1, other.y2)
        || rect.contains(other.x2, other.y1)
        || rect.contains(other.x2, other.y2)
        || other.contains(rect.x1, rect.y1)
        || other.contains(rect.x1, rect.y2)
        || other.contains(rect.x2, rect.y1)
        || other.contains(rect.x2, rect.y2)
}