import { ColorLike, Rect, AnimationQueue, ComponentEvent, Point, Shape, Vector, View, getObjectKeyIndex, Color } from 'outpost';
import { PLAYER_DIRECTIONS, Player, PlayerDirection } from './player.ts';
import { GameObject } from './puzzle.ts';
import { DEFAULT_X, DEFAULT_Y, GRID_HEIGHT, GRID_WIDTH, LEADERBOARD_SIZE, PLAYER_SPEED, PLUG_CHARGE_TIME_SECS, TIME_BAR_HEIGHT, TIME_BEFORE_WAVE_START_SECS, VIEWPORT_HEIGHT, VIEWPORT_WIDTH } from './constants.ts';
import { PuzzleManager } from './puzzle-manager.ts';
import { GameResult, compareResults, storeGameResults } from './store-result.ts';
import { rectsIntersect } from './utils.ts';
import { getColoredSprite } from './sprite.ts';
import { SceneId } from './scene-id.ts';

import doorSprite from '../assets/sprite/door.png';
import keySprite from '../assets/sprite/key.png';
import switchSprite from '../assets/sprite/switch.png'
import trapSprite from '../assets/sprite/trap.png'
import plugSprite from '../assets/sprite/plug.png'

export type RoomState = 'lobby' | 'game' | 'victory' | 'defeat';
export type AnimationData = {
    objectId: string;
    currentFrame: number;
    initialFrame: number;
    isLooping?: boolean
    mapIndex: (index: number) => number;
    isMaxFrame: (index: number) => boolean;
    onAnimationEnd?: () => void;
}

export class Room {
    id: string;
    private players: (Player | null)[] = [];
    private playerNames: string[] = [];
    private objects: GameObject[] = [];
    private waveIndex: number = -1;
    private waveTotalTimeSecs: number = 0;
    private waveRemainingTimeSecs: number = 0;
    private waveRemainingTimeBeforeStartSecs: number = 0;
    private state: RoomState = 'lobby';
    private endTime: number = 0;
    private results: GameResult[];
    private resultsToDisplay: GameResult[] = [];

    private animationsData: AnimationData[] = [];
    private sharedAnimationTimer = 0;

    constructor(id: string, results: GameResult[]) {
        this.id = id;
        this.results = results;
    }

    getPlayers(): (Player | null)[] {
        return this.players;
    }

    removePlayer(player: Player) {
        let index = this.players.indexOf(player);

        if (index === -1) {
            return;
        }

        if (this.state === 'lobby') {
            this.players.splice(index, 1);
        } else {
            this.players[index] = null;
        }

        player.room = null;
    }

    addPlayer(player: Player) {
        if (this.state === 'lobby') {
            this.players.push(player);
            player.room = this;
        }
    }

    isEmpty(): boolean {
        return !this.players.find(p => p);
    }

    isLobby(): boolean {
        return this.state === 'lobby';
    }

    hasFinished(): boolean {
        return this.state === 'victory' || this.state === 'defeat';
    }

    private isRunning(): boolean {
        return this.state === 'game';
    }

    getPlayerIndex(player: Player): number {
        return this.players.indexOf(player);
    }

    start() {
        if (this.state === 'lobby') {
            this.state = 'game';
            this.playerNames = this.players.map(p => p?.name ?? '').filter(name => name);
        }
    }

    private end(state: 'victory' | 'defeat') {
        this.state = state;
        this.objects = [];
        this.endTime = Date.now();
        this.waveRemainingTimeBeforeStartSecs = 0;

        let result = {
            endDate: this.endTime,
            playerNames: this.playerNames,
            waveBeatenCount: this.waveIndex,
            roomName: this.id
        };

        let index = 0;

        while (index < this.results.length && compareResults(result, this.results[index]) >= 0) {
            index += 1;
        }

        this.results.splice(index, 0, result);
        storeGameResults(this.results);

        this.resultsToDisplay = this.results
            .filter(result => result.playerNames.length === this.playerNames.length)
            .slice(0, LEADERBOARD_SIZE);
    }

    animate({ object, playBackward = false, syncWithLastAnimation = false, onAnimationEnd }: { object: GameObject; onAnimationEnd?: () => void; syncWithLastAnimation?: boolean; playBackward?: boolean; }) {
        const objectId = `object-${object.x}-${object.y}`;
        // remove existing door animation for this door : cut previous animation.
        const prevAnimation = this.animationsData.find(a => a.objectId === objectId);
        prevAnimation?.onAnimationEnd?.();
        this.animationsData = this.animationsData.filter(d => d.objectId !== objectId);
        this.animationsData.push({
            objectId,
            mapIndex: (index: number) => playBackward ? index - 1 : index + 1,
            onAnimationEnd,
            initialFrame: (syncWithLastAnimation ? prevAnimation?.currentFrame : undefined) ?? (playBackward ? 8 : 0),
            currentFrame: (syncWithLastAnimation ? prevAnimation?.currentFrame : undefined) ?? (playBackward ? 8 : 0),
            isMaxFrame: (index: number) => playBackward ? index <= 0 : index >= 8
        });
    }

    tryOpenDoor(doorObject: GameObject, deleteAfter: boolean = false) {
        if (doorObject.isOpen) {
            return;
        }

        if (deleteAfter) {
            this.objects.remove(doorObject);
        } else {
            doorObject.isOpen = true;
        }

        // this.animate({
        //     object: doorObject
        // });
    }

    tryCloseDoor(doorObject: GameObject) {
        if (!doorObject.isOpen) {
            return;
        }

        doorObject.isOpen = false;

        // this.animate({
        //     object: doorObject,
        //     playBackward: true
        // });
    }

    updateAnimatedObjects(elapsedSecs: number) {
        this.sharedAnimationTimer += elapsedSecs;

        // This is constant for all animation in the game.
        const FRAME_PER_SECOND = 8;
        const frameDuration = 1 / FRAME_PER_SECOND;

        if (this.sharedAnimationTimer > frameDuration) {
            this.sharedAnimationTimer -= frameDuration;

            for (let animation of this.animationsData) {
                let prevFrame = animation.currentFrame;
                animation.currentFrame = animation.mapIndex(animation.currentFrame);
                if (animation.isMaxFrame(animation.currentFrame)) {
                    animation.currentFrame = prevFrame;
                    animation.onAnimationEnd?.();

                    if (animation.isLooping) {
                        animation.currentFrame = animation.initialFrame
                    }
                }
            }
        }
    }

    update(elapsedSecs: number, puzzleManager: PuzzleManager): boolean {
        if (this.state === 'lobby') {
            return false;
        }

        this.updateAnimatedObjects(elapsedSecs);

        let waveFinished = this.objects.every(object => object.kind !== 'plug');

        if (waveFinished && !this.hasFinished()) {
            this.animationsData = [];
            this.waveIndex += 1;
            this.objects.length = 0;

            let waveContent = puzzleManager.generateWave(this.waveIndex, this.players.length);

            if (!waveContent) {
                this.end('victory');
                return true;
            }

            for (let player of this.players) {
                if (!player) {
                    continue;
                }

                player.position.x = DEFAULT_X;
                player.position.y = DEFAULT_Y;
                player.keyboardDirections = {};
                player.targetMovementPoint = null;
                player.carriedKeyId = null;
            }

            let { puzzleNames, durationSecs } = waveContent;

            this.waveTotalTimeSecs = durationSecs;
            this.waveRemainingTimeSecs = durationSecs;
            this.waveRemainingTimeBeforeStartSecs = TIME_BEFORE_WAVE_START_SECS;

            for (let puzzleName of puzzleNames) {
                let puzzle = puzzleManager.getPuzzle(puzzleName);

                for (let object of puzzle.objects) {
                    this.objects.push(structuredClone(object));
                }
            }

            for (let object of this.objects) {
                if (object.kind === 'plug') {
                    object.remainingChargeTime = PLUG_CHARGE_TIME_SECS;
                } else if (object.kind === 'door') {
                    object.requiredKeysToOpen = this.objects.reduce((acc, obj) => acc + (+!!(obj.kind === 'key' && obj.linkId === object.linkId)), 0);
                    object.remainingKeysToOpen = object.requiredKeysToOpen;
                }
            }
        }

        if (this.waveRemainingTimeBeforeStartSecs <= 0) {
            let vector = new Vector(0, 0);

            let hasCollideWithSwitch: { [index: string]: boolean } = {}

            for (let player of this.players) {
                if (!player) {
                    continue;
                }

                player.update(elapsedSecs, this.animationsData);

                vector.x = 0;
                vector.y = 0;

                if (player.targetMovementPoint) {
                    let movementLength = Math.min(player.targetMovementPoint.getDistanceTo(player.position), 1);
                    let movementVector = player.position.getVectorTo(player.targetMovementPoint);

                    if (movementLength > elapsedSecs * PLAYER_SPEED) {
                        vector.x += movementVector.x;
                        vector.y += movementVector.y;
                    } else {
                        player.targetMovementPoint = null;
                    }
                }

                for (let key in player.keyboardDirections) {
                    let direction = key as PlayerDirection;

                    if (player.keyboardDirections[direction]) {
                        let { dx, dy } = PLAYER_DIRECTIONS[direction];

                        vector.x += dx;
                        vector.y += dy;
                    }
                }

                let newPosition = player.position.add(vector.withLength(elapsedSecs * PLAYER_SPEED));
                let dx = newPosition.x - player.position.x;
                let dy = newPosition.y - player.position.y;
                let collisions = this.getCollisions(newPosition, player.size);
                let canMoveHorizontally = true;
                let canMoveVertically = true;

                for (let object of collisions) {
                    if (object.kind === 'wall') {
                        let horizontalOk = false;
                        let verticalOk = false;

                        if (Math.abs(object.x - player.position.x) > object.size / 2 + player.size / 2) {
                            verticalOk = true;
                        }

                        if (Math.abs(object.y - player.position.y) > object.size / 2 + player.size / 2) {
                            horizontalOk = true;
                        }

                        canMoveHorizontally &&= horizontalOk;
                        canMoveVertically &&= verticalOk;
                    } else if (object.kind === 'plug') {
                        object.remainingChargeTime -= elapsedSecs;

                        if (object.remainingChargeTime < 0) {
                            this.objects.remove(object);
                        }
                    } else if (object.kind === 'trap') {
                        canMoveHorizontally = false;
                        canMoveVertically = false;
                        player.position.x = DEFAULT_X;
                        player.position.y = DEFAULT_Y;
                    } else if (object.kind === 'key') {
                        if (!player.carriedKeyId && object.linkId) {
                            player.carriedKeyId = object.linkId;
                            this.objects.remove(object);
                        }
                    } else if (object.kind === 'door') {
                        if (object.linkId === player.carriedKeyId) {
                            object.remainingKeysToOpen -= 1;
                            player.carriedKeyId = null;

                            if (object.remainingKeysToOpen === 0) {
                                this.tryOpenDoor(object, true);
                            }
                        }

                        if ((object.remainingKeysToOpen > 0 || object.requiredKeysToOpen === 0) && !object.isOpen) {
                            let horizontalOk = false;
                            let verticalOk = false;

                            if (Math.abs(object.x - player.position.x) > object.size / 2 + player.size / 2) {
                                verticalOk = true;
                            }

                            if (Math.abs(object.y - player.position.y) > object.size / 2 + player.size / 2) {
                                horizontalOk = true;
                            }

                            canMoveHorizontally &&= horizontalOk;
                            canMoveVertically &&= verticalOk;
                        }
                    } else if (object.kind === 'switch') {
                        hasCollideWithSwitch[object.x + "-" + object.y] = true

                        if (!object.isOpen) {
                            console.log(Date.now() + ' Open switch !');
                            object.isOpen = true; // the switch just go entered.

                            // notify related doors.
                            for (let other of this.objects) {
                                if (other.kind === 'door' && other.linkId === object.linkId) {

                                    if (!other.isOpen) {
                                        this.tryOpenDoor(other);
                                    }
                                }
                            }
                        }
                    }
                }

                if (newPosition.x < 0 || newPosition.x > GRID_WIDTH - 1) {
                    canMoveHorizontally = false;
                }

                if (newPosition.y < 0 || newPosition.y > GRID_HEIGHT - 1) {
                    canMoveVertically = false;
                }

                if (canMoveHorizontally) {
                    player.position.x = newPosition.x;
                }

                if (canMoveVertically) {
                    player.position.y = newPosition.y;
                }
            }

            for (let object of this.objects) {
                if (object.kind === 'switch' && !hasCollideWithSwitch[object.x + '-' + object.y]) {
                    if (object.isOpen) { // ths switch just go left.
                        object.isOpen = false;
                        console.log(Date.now() + ' Close switch !');

                        // notify related doors.
                        for (let other of this.objects) {
                            if (other.kind === 'door' && other.linkId === object.linkId) {
                                this.tryCloseDoor(other);
                            }
                        }
                    }
                }
            }
        }

        if (this.isRunning()) {
            if (this.waveRemainingTimeBeforeStartSecs > 0) {
                this.waveRemainingTimeBeforeStartSecs -= elapsedSecs;
            } else {
                this.waveRemainingTimeSecs -= elapsedSecs;
            }

            if (this.waveRemainingTimeSecs < 0) {
                this.end('defeat');
            }
        }

        return true;
    }

    private getCollisions(position: Point, size: number): GameObject[] {
        let list: GameObject[] = [];
        let playerRect = new Rect(position.x, position.y, size, size);
        let objectRect = new Rect(0, 0, 0, 0);

        for (let object of this.objects) {
            objectRect.x = object.x;
            objectRect.y = object.y;
            objectRect.width = object.size;
            objectRect.height = object.size;

            if (rectsIntersect(playerRect, objectRect)) {
                list.push(object);
            }

            // if (Math.sqrt((position.x - object.x) ** 2 + (position.y - object.y) ** 2) < size / 2 + object.size / 2) {
            // list.push(object);
            // }
        }

        return list;
    }

    cheatAddTime() {
        this.waveRemainingTimeSecs += 30;
        this.waveTotalTimeSecs += 30;
    }

    getHost(): Player {
        return this.players.find(player => player)!;
    }

    getPlayerCount(): number {
        return this.players.reduce((acc, player) => acc + (+!!player), 0);
    }

    render(view: View, puzzleManager: PuzzleManager) {
        view.paint().backgroundColor('#aaaaff')

        for (let { x, y, kind, size, linkId, remainingChargeTime, isOpen, requiredKeysToOpen, remainingKeysToOpen } of this.objects) {
            let backgroundColor = kind === "wall" ? "#5555aa" : puzzleManager.getKindColor(kind);
            let borderColor = linkId === 0 ? "#00000000" : puzzleManager.getLinkIdColor(linkId);
            let shape: Shape = kind === 'plug' ? 'circle' : 'rectangle';
            let graphics = view.paint(`object-${x}-${y}`);
            let alpha = isOpen ? 0.2 : 1;
            // let icon = (kind as string).substring(0, 1).toUpperCase();

            graphics
                .x(x + 0.5)
                .y(y + 0.5)
                .width(size)
                .height(size)
                .shape(shape);

            if (kind === 'door') {
                graphics
                    .imageSpriteCountPerColumn(1)
                    .imageSpriteCountPerRow(8)
                    .imageSpriteIndex(isOpen ? 7 : 0)
                    // this.animationsData.find(a => a.objectId === `object-${x}-${y}`)?.currentFrame ?? 0)
                    .imageUrl(getColoredSprite(doorSprite, Color.from(puzzleManager.getLinkIdColor(linkId))));
            } else if (kind === 'key') {
                graphics
                    .imageUrl(getColoredSprite(keySprite, Color.from(puzzleManager.getLinkIdColor(linkId))));
            } else if (kind === "switch") {
                graphics
                    .imageSpriteCountPerColumn(1)
                    .imageSpriteCountPerRow(2)
                    .imageSpriteIndex(isOpen ? 1 : 0)
                    .imageUrl(getColoredSprite(switchSprite, Color.from(puzzleManager.getLinkIdColor(linkId))));
            } else if (kind === "plug") {
                graphics
                    .imageUrl(plugSprite);
            } else if (kind === "trap") {
                graphics
                    .imageUrl(trapSprite);
            } else {
                graphics.backgroundColor(backgroundColor)
                    .borderWidth('7%')
                    .borderColor(borderColor)
                    .borderAlpha(alpha)
                    .backgroundAlpha(alpha)
                    // .text(icon)
                    .textSize('70%');
            }

            if (kind === 'plug' && (remainingChargeTime > 0 || remainingChargeTime < PLUG_CHARGE_TIME_SECS)) {
                let percent = 1 - remainingChargeTime / PLUG_CHARGE_TIME_SECS;

                graphics
                    .borderFillPercent(percent)
                    .borderWidth(0.2)
                    .borderColor('yellow');
            }

            if (kind === 'door') {
                let collected = requiredKeysToOpen - remainingKeysToOpen;
                let text = `${collected} / ${requiredKeysToOpen}`;

                view.paint(`object-${x}-${y}-tooltip`)
                    .x(x + 0.5)
                    .y(y + 0.5 - 0.8)
                    .width(3)
                    .height(1)
                    .text(text)
                    .textSize('60%')
            }
        }

        for (let player of this.players) {
            if (!player) {
                continue;
            }

            player.renderInGameCharacter(view, puzzleManager, this.animationsData);
        }

        if (this.waveRemainingTimeBeforeStartSecs > 0) {
            let text = Math.ceil(this.waveRemainingTimeBeforeStartSecs).toString();

            view.paint('cooldown-start')
                .x(GRID_WIDTH / 2)
                .y(GRID_HEIGHT / 2)
                .width(8)
                .height(8)
                .text(text)
                .textSize('70%')
                .backgroundColor('white')
                .backgroundAlpha(0.2)
                .borderRadius('15%');
        }

        if (this.waveRemainingTimeSecs > 0) {
            let text = Math.ceil(this.waveRemainingTimeSecs).toString();
            let baseRect = Rect.fromSize(VIEWPORT_WIDTH, VIEWPORT_HEIGHT).fromTop(TIME_BAR_HEIGHT);
            let ratio = 1 - (this.waveTotalTimeSecs - this.waveRemainingTimeSecs) / this.waveTotalTimeSecs;

            view.paint('cooldown-wave')
                .sceneId(SceneId.Bar)
                .rect(baseRect)
                .backgroundColor('white')

            view.paint('cooldown-wave-bar')
                .sceneId(SceneId.Bar)
                .rect(baseRect.fromLeft(baseRect.width * ratio))
                .backgroundColor('red');

            view.paint('cooldown-wave-text')
                .sceneId(SceneId.Bar)
                .rect(baseRect)
                .text(text)
                .textSize('70%');
        }

        if (this.hasFinished()) {
            this.renderLeaderboard(view);
        }
    }

    renderLeaderboard(view: View) {
        let playerCount = this.playerNames.length;
        let beatenWaveCount = this.waveIndex;
        let s = beatenWaveCount > 1 ? 's' : '';
        let color: ColorLike = this.state === 'victory' ? 'green' : 'red';
        let text = this.state === 'victory' ?
            `Victorious after ${beatenWaveCount} level${s}!` :
            `Defeated after ${beatenWaveCount} level${s}.`;

        view.layout()
            .topToBottom()
            .childHeight('15%')
            .addChild(null, {
                text,
                textColor: color,
                textSize: '50%'
            });

        let layout = view.layout()
            .setRootRect(view.getRect().fromBottom('100%', '80%'))
            .topToBottom()
            .innerMargin('6%')
            .childHeight('10%')
            .addChild(null, {
                text: `Leaderboard ~(${playerCount} player${playerCount > 1 ? 's' : ''})~`,
            })
            .addChild(null)
            .height('2%')
            .childHeight('5%');


        for (let i = 0; i < this.resultsToDisplay.length; ++i) {
            let result = this.resultsToDisplay[i];
            let rank =
                i === 0 ? '1st' :
                    i === 1 ? '2nd' :
                        i === 2 ? '3rd' :
                            `${i + 1}th`;


            let text = `${rank} (level ${result.waveBeatenCount + 1}): ${result.playerNames.join(', ')}`;

            layout = layout.addChild(null, {
                text,
            });
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Room);