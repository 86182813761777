import { Component, ColorLike, ComponentApi, clamp } from 'outpost';
import { OBJECT_SIZES, LINK_ID_TO_COLOR } from './constants.ts';
import { Puzzle, PuzzleGroup, PuzzleWave, ObjectKind, GameObject, CELL_KINDS, PuzzleWaveContent } from './puzzle.ts';
import puzzleData from '../assets/data/data.json';

export class PuzzleManager implements Component {
    private puzzles: { [key: string]: Puzzle } = {};
    private groups: { [key: string]: PuzzleGroup } = {};
    private waves: PuzzleWave[] = [];
    private cellColors: Partial<{ [Key in ObjectKind]: ColorLike }> = {};

    onMount(api: ComponentApi): void {
        this.load(puzzleData);
    }

    load(json: any) {
        for (let { name, grid, groupId } of json.puzzles) {
            let objects: GameObject[] = [];

            for (let { x, y, kind, linkId } of grid) {
                if (CELL_KINDS.includes(kind)) {
                    linkId = linkId || 0;

                    let requiredKeysToOpen = 0;
                    let remainingKeysToOpen = 0;
                    let remainingChargeTime = 0;
                    let isOpen = false;
                    let size = OBJECT_SIZES[kind as ObjectKind];

                    objects.push({ x, y, kind, linkId, size, requiredKeysToOpen, remainingChargeTime, remainingKeysToOpen, isOpen });
                }
            }

            let group = this.groups[groupId];

            if (!group) {
                group = { puzzleNames: [] };
                this.groups[groupId] = group;
            }

            group.puzzleNames.push(name);

            this.puzzles[name] = { objects };
        }

        for (let { players } of json.wave) {
            let playerCountToContent = {} as any;
            let count = 1;

            for (let { groupIds, durationInSecond } of players) {
                let content: PuzzleWaveContent = {
                    durationsSecs: durationInSecond,
                    puzzleGroupIds: groupIds
                };

                // content.durationsSecs = 1;

                playerCountToContent[count] = content;
                count += 1;
            }

            this.waves.push({ playerCountToContent });
        }

        for (let { kind, color } of json.cells) {
            this.cellColors[kind as ObjectKind] = color;
        }
    }

    getKindColor(kind: ObjectKind | undefined): ColorLike {
        return (kind && this.cellColors[kind]) ?? 'black';
    }

    getPuzzle(name: string): Puzzle {
        let puzzle = this.puzzles[name];

        if (!puzzle) {
            throw new Error(`no puzzle with name "${name}"`);
        }

        return puzzle;
    }

    generateWave(waveIndex: number, playerCount: number): { durationSecs: number, puzzleNames: string[] } | null {
        let wave = this.waves[waveIndex];

        if (!wave) {
            return null;
        }

        let count = clamp(playerCount, 1, 4);
        let waveContent: PuzzleWaveContent = wave.playerCountToContent[count] ?? { durationsSecs: 0, puzzleGroupIds: [] };
        let durationSecs = waveContent.durationsSecs;
        let puzzleNames: string[] = [];
        let groups = structuredClone(this.groups);

        for (let groupId of waveContent.puzzleGroupIds) {
            let group = groups[groupId];

            if (!group || group.puzzleNames.length === 0) {
                continue;
            }

            let index = Math.random() * group.puzzleNames.length | 0;
            let name = group.puzzleNames[index];

            puzzleNames.push(name);
            group.puzzleNames.splice(index, 1);
        }

        return { durationSecs, puzzleNames };
    }

    getLinkIdColor(linkId: number = 0): ColorLike {
        if (linkId === 0) {
            return '#ff00ff';
        }

        return LINK_ID_TO_COLOR[(linkId - 1) % LINK_ID_TO_COLOR.length];
    }
}
globalThis.ALL_FUNCTIONS.push(PuzzleManager);