import { Color } from "outpost"

let canvas!: HTMLCanvasElement;
let ctx!: CanvasRenderingContext2D;
let dynamicImages: {
    [index: string]: {
        dataUrl: string,
        isReady: boolean
    }
} = {}

export function getColoredSprite(spritePath: string, color: Color): string {
    const spriteKey = spritePath + "_" + color.toString()

    if (dynamicImages[spriteKey]) {
        const sprite = dynamicImages[spriteKey]
        if (sprite.isReady) {
            return sprite.dataUrl
        }

        return spritePath
    }

    console.log('Creating new image for ' + spritePath + ' with color ' + color.toString())

    dynamicImages[spriteKey] = {
        dataUrl: '',
        isReady: false,
    }

    if (!canvas) {
        canvas = document.createElement('canvas');
        ctx = canvas.getContext('2d', { willReadFrequently: true })!;
    }

    const image = new Image();
    image.src = spritePath;

    image.onerror = function (...err) {
        console.log(spriteKey, err)
    }

    image.onload = function () {
        canvas.width = image.width
        canvas.height = image.height

        console.log('Draw image for ' + spriteKey + ' ' + canvas.width + ' / ' + canvas.height)

        ctx.drawImage(image, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            // Check if the pixel is magenta (#ff00ff)
            if (data[i] === 255 && data[i + 1] === 0 && data[i + 2] === 255) {
                // Change to your custom color, for example, red
                data[i] = Math.floor(color.r * 255);
                data[i + 1] = Math.floor(color.g * 255);
                data[i + 2] = Math.floor(color.b * 255);
                data[i + 3] = 255
                // Alpha (data[i + 3]) remains unchanged
            }
        }

        ctx.putImageData(imageData, 0, 0);

        dynamicImages[spriteKey].dataUrl = canvas.toDataURL('png')
        dynamicImages[spriteKey].isReady = true

        // console.log(dynamicImages[spriteKey].dataUrl)
    };

    return spritePath
}
