import { existsSync, readFileSync, writeFileSync } from 'fs';
import { OUTPOST_ENV } from 'outpost';
import { join } from 'path';

export type GameResult = {
    roomName: string;
    playerNames: string[];
    waveBeatenCount: number;
    endDate: number;
};

function getFilePath(): string {
    return join(OUTPOST_ENV.SERVER_DATA_DIR, 'results.json');
}

export function loadGameResults(): GameResult[] {
    let filePath = getFilePath();

    if (!existsSync(filePath)) {
        return [];
    } else {
        return JSON.parse(readFileSync(filePath, 'utf8'));
    }
}

export function storeGameResults(results: GameResult[]) {
    let filePath = getFilePath();

    writeFileSync(filePath, JSON.stringify(results, null, '  '), 'utf8');
}

export function compareResults(r1: GameResult, r2: GameResult): number {
    let score = r2.waveBeatenCount - r1.waveBeatenCount;

    if (score === 0) {
        score = r1.endDate - r2.endDate;
    }

    return score;
}