import { ColorLike, KeyCode } from 'outpost';
import { PlayerDirection } from './player.ts';
import { ObjectKind } from './puzzle.ts';

export const PLAYER_COLORS: ColorLike[] = ['blue', 'red', 'gold', 'green'];
export const LINK_ID_TO_COLOR: ColorLike[] = ['blue', 'limegreen', 'deeppink', 'silver', 'darkred', 'saddlebrown', 'aqua', 'chartreuse', 'coral', 'darkcyan', 'darkkhaki', 'darkmagenta', 'darksalmon', 'blanchedalmond', 'yellowgreen', 'violet', 'thistle', 'teal', 'tomato', 'slategray', 'powderblue', 'indianred', 'honeydew', 'mediumslateblue', 'mediumturquoise', 'mediumpurple', 'sienna', 'rebeccapurple', 'plum', 'peachpuff', 'midnightblue', 'mintcream', 'mediumorchid', 'lightslategray', 'lightpink', 'gold', 'chocolate'];
export const GRID_WIDTH = 48;
export const GRID_HEIGHT = 27;
export const TIME_BAR_HEIGHT = 2;
export const VIEWPORT_WIDTH = GRID_WIDTH;
export const VIEWPORT_HEIGHT = GRID_HEIGHT + TIME_BAR_HEIGHT;
export const DEFAULT_X = GRID_WIDTH / 2;
export const DEFAULT_Y = GRID_HEIGHT / 2;
export const PLAYER_SPEED = 8.5;
export const PLUG_CHARGE_TIME_SECS = 0.8;
export const TIME_BEFORE_WAVE_START_SECS = 3;
// export const TIME_BEFORE_WAVE_START_SECS = 0;
export const DEFAULT_WAVE_DURATION_SECS = 30;
export const PLAYER_SIZE = 0.5;
export const OBJECT_SIZES: { [Key in ObjectKind]: number } = {
    door: 1,
    key: 1.2,
    plug: 1.2,
    switch: 1.2,
    trap: 0.8,
    wall: 1
};

export const KEY_TO_DIRECTION: Partial<{ [Key in KeyCode]: PlayerDirection }> = {
    'KeyW': 'top',
    'KeyS': 'bottom',
    'KeyA': 'left',
    'KeyD': 'right',
    'ArrowUp': 'top',
    'ArrowDown': 'bottom',
    'ArrowLeft': 'left',
    'ArrowRight': 'right',
};

export const LEADERBOARD_SIZE = 3;
export const AUTO_JOIN_TEST_ROOM = false;

export const EXPLANATION = [
    '• Move with arrow keys, WASD / ZQSD,\nor the mouse.',
    '',
    '• Collect all the [plug] to win.',
].join('\n');