import { Color, ColorLike, Point, Vector, View } from 'outpost';
import { AnimationData, Room } from './room.ts';
import { PLAYER_COLORS, PLAYER_SIZE } from './constants.ts';
import { PuzzleManager } from './puzzle-manager.ts';

import walkingSprite from '../assets/sprite/battery-walk.png'
import { getColoredSprite } from './sprite.ts';
import keySprite from '../assets/sprite/key.png'

export const PLAYER_DIRECTIONS = {
    left: { dx: -1, dy: 0 },
    right: { dx: 1, dy: 0 },
    top: { dx: 0, dy: -1 },
    bottom: { dx: 0, dy: 1 },
} satisfies { [key: string]: { dx: number, dy: number } };

export type PlayerDirection = keyof typeof PLAYER_DIRECTIONS;

export class Player {
    id: string;
    name: string = '<name>';
    room: Room | null = null;
    size: number = PLAYER_SIZE;
    position: Point = Point.zero();
    keyboardDirections: Partial<{ [Key in PlayerDirection]: boolean }> = {};
    targetMovementPoint: Point | null = null;
    showIndicator: boolean = false;
    carriedKeyId: number | null = null;
    private animation!: AnimationData

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    update(elapsedSecond: number, animationsData: AnimationData[]) {
        const id = `player-key-${this.id}`
        if (animationsData.some(a => a.objectId === id)) {
            return
        }

        this.animation = {
            objectId: id,
            currentFrame: 0,
            initialFrame: 0,
            isLooping: true,
            mapIndex(index) {
                return index + 1
            },
            isMaxFrame(index) {
                return index >= 5
            },
        }

        animationsData.push(this.animation)
    }

    renderInGameCharacter(view: View, puzzleManager: PuzzleManager, animationsData: AnimationData[]) {
        if (this.targetMovementPoint && this.showIndicator && view.getClientId() === this.id) {
            view.paint(`player-${this.id}-target`)
                .shape('circle')
                .x(this.targetMovementPoint.x + 0.5)
                .y(this.targetMovementPoint.y + 0.5)
                .width(0.3)
                .height(0.3)
                .text('❌')
        }

        view.paint(`player-${this.id}-shadow`)
            .shape('circle')
            .x(this.position.x + 0.5)
            .y(this.position.y + 0.5)
            .width(1)
            .height(0.5)
            .borderColor(this.getColor())
            .borderWidth(.05)

        view.paint(`player-${this.id}`)
            .x(this.position.x + 0.5)
            .y(this.position.y + 0.5)
            .offsetY(-1)
            .width(2)
            .height(2.5)

            .imageSpriteCountPerColumn(4)
            .imageSpriteCountPerRow(5)
            .imageSpriteIndex(this.animation?.currentFrame ?? 0)
            .imageUrl(getColoredSprite(walkingSprite, Color.from(this.getColor())))

        // .shape('triangle')
        // .backgroundColor(this.getColor())

        if (this.carriedKeyId) {
            view.paint(`player-key-${this.id}`)
                .x(this.position.x + 0.5)
                .y(this.position.y + 0.5 - 2.5)
                .width(1)
                .height(1)
                .imageUrl(getColoredSprite(keySprite, Color.from(puzzleManager.getLinkIdColor(this.carriedKeyId))))
        }
    }

    getColor(): ColorLike {
        let index = this.room?.getPlayerIndex(this) ?? -1;

        return PLAYER_COLORS[index % PLAYER_COLORS.length];
    }
}
globalThis.ALL_FUNCTIONS.push(Player);