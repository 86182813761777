export const CELL_KINDS = <const>['wall', 'key', 'door', 'plug', 'trap', 'switch'];
export type ObjectKind = typeof CELL_KINDS[number];

export type GameObject = {
    x: number;
    y: number;
    kind: ObjectKind;
    linkId?: number;
    requiredKeysToOpen: number;
    remainingKeysToOpen: number;
    remainingChargeTime: number;
    size: number;
    isOpen: boolean;
};

export type Puzzle = {
    objects: GameObject[]
};

export type PuzzleGroup = {
    puzzleNames: string[];
};

export type PuzzleWave = {
    playerCountToContent: { [count: number]: PuzzleWaveContent };
};

export type PuzzleWaveContent = {
    puzzleGroupIds: string[];
    durationsSecs: number;
};