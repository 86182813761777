import { Component, ComponentApi, View, Constructor, Rect, Color, KeyboardInput, MouseInput, Vector, Point } from 'outpost';
import { PuzzleManager } from './puzzle-manager.ts';
import { AUTO_JOIN_TEST_ROOM, EXPLANATION, GRID_HEIGHT, GRID_WIDTH, KEY_TO_DIRECTION, TIME_BAR_HEIGHT, VIEWPORT_HEIGHT, VIEWPORT_WIDTH } from './constants.ts';
import { Player } from './player.ts';
import { State } from './state.ts';
import { Room } from './room.ts';
import { Button } from './button.ts';
import { GameResult, loadGameResults } from './store-result.ts';
import { SceneId } from './scene-id.ts';

import backgroundSprite from '../assets/landing.png';
import titleSprite from '../assets/power-squad.png';
import keySprite from '../assets/key-landing.png';

export class RootComponent implements Component {
    state: State = new State();
    shouldUpdate: boolean = false;
    results: GameResult[] = [];

    serializableConstructors: Constructor[] = [State, Player, Room];
    items = {
        puzzleManager: new PuzzleManager(),
        joinDefaultButton: new Button('Quick Play', api => this.addPlayerToRoom(api, 'default'), { backgroundColor: "tomato", textColor: "gold", borderColor: "gold" }),
        joinCustomButton: new Button('Custom game', api => this.addPlayerToRoom(api), { backgroundColor: "royalblue", textColor: "lightblue", borderColor: "lightblue" }),
        startButton: new Button('Start', api => this.startRoom(api), { backgroundColor: "tomato", textColor: "gold", borderColor: "gold" }),
        leaveButton: new Button('Leave', api => this.removePlayerFromRoom(api), { backgroundColor: "royalblue", textColor: "lightblue", borderColor: "lightblue" }),
        cheat: { runInteraction: (api: ComponentApi) => this.processCheatCommand(api) },
        controller: { runInteraction: (api: ComponentApi) => this.processPlayerInput(api) },
    };

    private scheduleUpdate() {
        this.shouldUpdate = true;
    }

    private getPlayer(api: ComponentApi): Player {
        let player = this.state.players[api.clientId];

        if (!player) {
            api.abort();
            // throw new Error(`no player ${api.clientId}`);
        }

        return player;
    }

    private getPlayerAndRoom(api: ComponentApi): { player: Player, room: Room; } {
        let player = this.getPlayer(api);
        let room = player.room;

        if (!room) {
            api.abort();
            // throw new Error(`no room for player ${api.clientId}`);
        }

        return { player, room };
    }

    private async startRoom(api: ComponentApi) {
        let { player, room } = this.getPlayerAndRoom(api);

        await api.waitForServerResponse();

        if (api.isClientContext()) {
            return;
        }

        if (room.getHost() === player) {
            room.start();
            this.scheduleUpdate();
        }
    }

    private async addPlayerToRoom(api: ComponentApi, forceRoomId?: string) {
        let player = this.getPlayer(api);

        if (player.room) {
            return;
        }

        let roomId = forceRoomId
            ? forceRoomId
            : api.prompt('Enter the room name (it will be created if it does not exist):');

        if (!roomId) {
            return;
        }

        await api.waitForServerResponse();

        if (api.isClientContext()) {
            return;
        }

        this.triggerAddPlayerToRoom(player, roomId);
    }

    private triggerAddPlayerToRoom(player: Player, roomId: string) {
        let room = this.state.rooms[roomId];

        if (!room) {
            room = new Room(roomId, this.results);
            this.state.rooms[roomId] = room;
        }

        room.addPlayer(player);
        this.scheduleUpdate();
    }

    private async removePlayerFromRoom(api: ComponentApi) {
        let { player, room } = this.getPlayerAndRoom(api);

        if (!room.hasFinished() && !room.isLobby()) {
            return;
        }

        await api.waitForServerResponse();

        if (api.isClientContext()) {
            return;
        }

        room.removePlayer(player);

        this.scheduleUpdate();
    }

    private async processPlayerInput(api: ComponentApi) {
        let { player, room } = this.getPlayerAndRoom(api);

        if (!room) {
            return;
        }

        let input = await api.waitForUserInput({
            captureMouse: true,
            captureKeyboard: true
        });

        if (input instanceof KeyboardInput) {
            let direction = KEY_TO_DIRECTION[input.code];

            if (!direction) {
                return;
            }

            api.waitForServerResponse();

            if (api.isClientContext()) {
                return;
            }

            player.keyboardDirections[direction] = input.action === 'down';
            player.targetMovementPoint = null;
            this.scheduleUpdate();
        } else if (input instanceof MouseInput) {
            let targetPoint: Point | null | undefined = undefined;
            let showIndicator: boolean | undefined = undefined;
            let isPressed: boolean = !api.getDragOffset().isZero() || input.action === 'down';

            if (input.action === 'down' || (input.action === 'move' && isPressed)) {
                targetPoint = new Point(input.x - 0.5, input.y - 2.5);
                showIndicator = !isPressed;
            } else if (input.action === 'up') {
                showIndicator = true;
            }

            if (targetPoint === undefined && showIndicator === undefined) {
                return;
            }

            api.waitForServerResponse();

            if (api.isClientContext()) {
                return;
            }

            if (targetPoint !== undefined) {
                player.targetMovementPoint = targetPoint;
            }

            if (showIndicator !== undefined) {
                player.showIndicator = showIndicator;
            }

            this.scheduleUpdate();
        }
    }

    async processCheatCommand(api: ComponentApi) {
        let { player, room } = this.getPlayerAndRoom(api);

        await api.waitForUserInput({
            shortcuts: { 'Shift_KeyT': {} }
        });

        await api.waitForServerResponse();

        if (api.isClientContext()) {
            return;
        }

        room.cheatAddTime();
        this.scheduleUpdate();
    }

    onServerTick(api: ComponentApi): void {
        let elapsedSecs = api.getTickDuration() / 1000;

        for (let room of Object.values(this.state.rooms)) {
            if (room.isEmpty()) {
                delete this.state.rooms[room.id];
            } else if (room.update(elapsedSecs, this.items.puzzleManager)) {
                this.scheduleUpdate();
            }
        }

        if (!this.shouldUpdate) {
            return;
        }

        this.shouldUpdate = false;

        api.emitEvent(this.state, {
            emitToServer: false
        });
    }

    onClientStart(api: ComponentApi): void {
        api.configureRenderer({
            virtualViewport: [VIEWPORT_WIDTH, VIEWPORT_HEIGHT],
            iconAliases: {
                '[plug]': 'assets/sprite/plug.png'
            }
        });

        api.getAnimationQueue()
            .updateScene({
                sceneId: SceneId.Default,
                properties: {
                    windowRect: Rect.fromSize(VIEWPORT_WIDTH, VIEWPORT_HEIGHT).fromBottom(GRID_HEIGHT),
                    cameraX: GRID_WIDTH / 2,
                    cameraY: GRID_HEIGHT / 2
                }
            });
    }

    onClientDisconnectServer(api: ComponentApi): void {
        let player = this.state.players[api.clientId];

        if (player) {
            if (player.room) {
                player.room.removePlayer(player);
            }

            delete this.state.players[api.clientId];
        }

        this.scheduleUpdate();
    }

    onEvent(api: ComponentApi): void {
        let newState = api.getEvent(State);

        this.state = newState;
        api.render();
    }

    async runInteraction(api: ComponentApi): Promise<void> {
        if (this.state.players[api.clientId]) {
            return;
        }

        let username = api.prompt('Enter your name:', 'username');

        if (!username) {
            return;
        }

        await api.waitForServerResponse();

        if (api.isClientContext()) {
            return;
        }

        this.state.players[api.clientId] = new Player(api.clientId, username);

        if (AUTO_JOIN_TEST_ROOM) {
            this.triggerAddPlayerToRoom(this.state.players[api.clientId], 'test');
        }

        this.scheduleUpdate();
    }

    onServerStart(api: ComponentApi): void {
        this.results = loadGameResults();
    }

    render(view: View): void {
        view.paint()
            .sceneId(SceneId.Default)
            .rect(Rect.fromSize(GRID_WIDTH, GRID_HEIGHT))
            .backgroundColor('white');

        let player = this.state.players[view.getClientId()];

        if (!player) {
            return;
        }

        if (!player.room) {
            this.renderHome(view);
        } else if (player.room.isLobby()) {
            this.renderLobby(view, player.room);
        } else {
            this.renderGame(view, player.room);

            if (player.room.hasFinished()) {
                this.renderBottomButtons(view, [this.items.leaveButton]);
            }
        }
    }

    renderTitle(view: View) {
        view.paint().imageUrl(backgroundSprite);
        view.paint('key').imageUrl(keySprite).rect(view.getRect().fromTopRight("30%", "30%"));
        view.paint('title').imageUrl(titleSprite).rect(view.getRect().fromTopLeft("50%", "50%"));
    }

    renderHome(view: View) {
        this.renderTitle(view);

        view.layout()
            .bottomLeftToTop()
            .outerMargin("18.5%")
            .childWidth('30%')
            .childAspectRatio(5)
            .innerMargin('5%')
            .addChild(this.items.joinCustomButton, { textHorizontalAlign: "center", globalScale: 0.7 })
            .addChild(this.items.joinDefaultButton, { textHorizontalAlign: "center" });
    }

    renderBottomButtons(view: View, buttons: Button[]) {
        view.layout()
            .bottomLeftToRight()
            .horizontalAlign('center')
            .childAspectRatio(4)
            .childHeight('10%')
            .margin('5%')
            .addChild(buttons);
    }

    renderLobby(view: View, room: Room) {
        view.layout()
            .topToBottom()
            .childHeight('15%')
            .addChild(null, {
                text: room.id === 'default' ? "Quick Play" : `Room: ${room.id}`,
                textSize: '50%'
            });

        let layout = view.layout()
            .setRootRect(view.getRect().fromCenter('100%', '70%'))
            .grid()
            .columnSize(4)
            .rowSize(1, Infinity)
            .direction('top-to-bottom')
            .childAspectRatio(6)
            .horizontalAlign('left');

        let host = room.getHost();

        for (let player of room.getPlayers()) {
            if (!player) {
                continue;
            }

            layout = layout.addChild(null, {
                // backgroundColor: 'tomato'
            })
                .leftToRight()
                .addChild(null, {
                    text: player === host ? '👑' : '',
                    textSize: '50%',
                    textHorizontalAlign: 'left',
                    offsetX: 2
                })
                .force(0.6)
                .addChild(null, {
                    offsetX: 2,
                    backgroundColor: player.getColor(),
                    shape: 'circle',
                    globalScale: 0.4,
                    anchorX: undefined,
                    anchorY: undefined,
                })
                .force(0.6)
                .aspectRatio(1)
                .addChild(null, {
                    offsetX: 2,
                    text: player.name,
                    textSize: '50%',
                    textHorizontalAlign: 'left',
                    textPadding: 1
                })
                .force(4)
                .parent();
        }

        view.layout()
            .setRootRect(view.getRect().fromTopRight('30%', '25%'))
            .addChild(null, {
                text: EXPLANATION,
                textSize: 0.8,
                textAllowMultiline: true,
                textHorizontalAlign: 'left',
            });

        let buttons = [this.items.leaveButton];

        if (host.id === view.getClientId()) {
            buttons.unshift(this.items.startButton);
        }

        this.renderBottomButtons(view, buttons);
    }

    renderGame(view: View, room: Room) {
        room.render(view, this.items.puzzleManager);
    }
}
globalThis.ALL_FUNCTIONS.push(RootComponent);